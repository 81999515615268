import { FC, useEffect, useState } from "react";
import { DashboardChildProps } from "hocs";
import { useQuery } from "hooks";
import {
  ExtendedTable,
  NavLink,
  ExtendedTag,
  Button,
  PlusOutlined,
} from "components";
import { CreateNew } from "./components";
import { Account } from "types";
import { routes } from "configs";
import { getQueryKey } from "utils";

const List: FC<DashboardChildProps> = ({ getTopics }) => {
  const { data = [], isLoading } = useQuery<Array<Account & { type: string }>>({
    apiName: "accounts",
    queryKey: getQueryKey("accountList"),
  });
  const [drawerOpen, toggleDrawer] = useState(false);

  useEffect(() => {
    getTopics({
      extra: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => toggleDrawer(true)}
        />
      ),
    });
  }, [getTopics]);

  const closeDrawer = () => {
    toggleDrawer(false);
  };

  return (
    <>
      <CreateNew drawerOpen={drawerOpen} onClose={closeDrawer} />
      <ExtendedTable<Account & { type: string }>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            dataIndex: "id",
            title: "common.id",
            render: ({ value }) => (
              <NavLink to={routes.accounts.view(value)}>{value}</NavLink>
            ),
          },
          {
            dataIndex: "account_name",
            title: "common.name",
            render: ({ value, record }) => (
              <ExtendedTag backgroundColor={record.color}>{value}</ExtendedTag>
            ),
          },
          {
            dataIndex: "type",
            title: "common.type",
          },
        ]}
      />
    </>
  );
};

export default List;
