import { FC } from "react";
import {
  InputEmail,
  InputText,
  InputSelect,
  Form,
  Button,
  FormattedMessage,
  Drawer,
} from "components";
import { useSelector, useMutation } from "hooks";
import { getQueryKey } from "utils";
import { Account } from "types";

interface FormValues {
  fistName: string;
  lastName: string;
  email: string;
  accountID: number;
}

interface CreateNewProps {
  drawerOpen: boolean;
  onClose: () => void;
}

const CreateNew: FC<CreateNewProps> = ({ drawerOpen, onClose }) => {
  const [form] = Form.useForm();
  const accountTypes = useSelector((state) => state.appState.accountTypes);

  const { mutate, isPending } = useMutation<Account, FormValues>({
    method: "post",
    apiName: "accounts",
    invalidateQueries: [getQueryKey("accountList")],
    onSuccess: () => onClose(),
  });

  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      destroyOnClose
      extra={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          <FormattedMessage id="button.submit" />
        </Button>
      }
    >
      <Form<FormValues>
        form={form}
        preserve={false}
        layout="vertical"
        onFinish={(values) => mutate(values)}
      >
        <InputText required name="account_name" label="common.name" />
        <InputText required name="billingName" label="billing.name" />

        <InputEmail required name="billingEmail" label="billing.email" />
        <InputSelect
          required
          name="typeId"
          label="common.type"
          options={accountTypes}
          getOptionProps={(opt) => ({
            children: opt.name,
            value: opt.id,
          })}
        />
      </Form>
    </Drawer>
  );
};

export default CreateNew;
