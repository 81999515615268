import { FC } from "react";
import { Tabs } from "components";
import * as LC from "./components";
import { viewHOC } from "hocs";
import { getQueryKey } from "utils";
import { ViewHOCChildProps } from "types";
import { useMutation } from "hooks";
import { TabProps } from "./types";

const View: FC<ViewHOCChildProps<TabProps["staff"]>> = ({
  record,
  queryKey,
}) => {
  const { mutate, isPending } = useMutation({
    apiName: "staff",
    path: `/${record.id}`,
    method: "patch",
    invalidateQueries: [queryKey],
  });

  const COMMON_PROPS = {
    staff: record,
    onFinish: mutate,
    submitting: isPending,
  };

  return (
    <Tabs
      defaultActiveKey="summary"
      items={[
        {
          key: "summary",
          label: "Summary",
          children: <LC.Summary {...COMMON_PROPS} />,
        },
        {
          key: "contact",
          label: "Contact",
          children: <LC.Contact {...COMMON_PROPS} />,
        },
        {
          key: "permissions",
          label: "Permissions",
          children: <LC.Permissions staff={record} queryKey={queryKey} />,
        },
      ]}
    />
  );
};

export default viewHOC({
  Component: View,
  queryKeyFn: ({ params }) => getQueryKey("staff", params.id),
  pathFn: ({ params }) => `/${params.id}`,
  apiName: "staff",
});
