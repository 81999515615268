import { FC, useEffect, useState } from "react";
import {
  ExtendedTable,
  NavLink,
  ExtendedTag,
  Button,
  PlusOutlined,
} from "components";
import { CreateNew } from "./components";
import { DashboardChildProps } from "hocs";
import { useQuery } from "hooks";
import { routes } from "configs";
import { getQueryKey } from "utils";
import { Staff } from "types";

const List: FC<DashboardChildProps> = ({ getTopics }) => {
  const queryKey = getQueryKey("staffList");
  const { data, isLoading } = useQuery<Staff[]>({
    apiName: "staff",
    queryKey,
  });
  const [drawerOpen, toggleDrawer] = useState(false);

  useEffect(() => {
    getTopics({
      extra: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => toggleDrawer(true)}
        />
      ),
    });
  }, [getTopics]);

  return (
    <>
      <CreateNew
        staffListQueryKey={queryKey}
        onClose={() => toggleDrawer(false)}
        drawerOpen={drawerOpen}
      />
      <ExtendedTable<Staff>
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend",
            render: ({ value }) => (
              <NavLink to={routes.staff.view(value)}>{value}</NavLink>
            ),
          },
          {
            title: "common.account",
            dataIndex: "accountName",
            render: ({ record }) => (
              <ExtendedTag backgroundColor={record.accountColor}>
                {record.accountName}
              </ExtendedTag>
            ),
          },
          {
            title: "common.first_name",
            dataIndex: "first_name",
          },
          {
            title: "common.last_name",
            dataIndex: "last_name",
          },
          {
            title: "common.email",
            dataIndex: "email",
          },
        ]}
      />
    </>
  );
};

export default List;
