import { FC } from "react";
import { routes } from "configs";
import { Button } from "components";
import { useMutation } from "hooks";
import { Staff } from "types";

interface RightTableFooterProps {
  disabled: boolean;
  staffIDs: string[];
  userID: number;
  onUserStaffUpdated: (newStaff: Staff[]) => void;
}

const RightTableFooter: FC<RightTableFooterProps> = ({
  disabled,
  staffIDs,
  userID,
  onUserStaffUpdated,
}) => {
  const { isPending, mutate } = useMutation<
    Staff[],
    { staffIDs: RightTableFooterProps["staffIDs"] }
  >({
    apiName: "users",
    path: routes.users.addStaff(userID),
    method: "post",
    onSuccess: (newStaff) => {
      onUserStaffUpdated(newStaff);
    },
  });

  return (
    <Button
      size="small"
      type="primary"
      loading={isPending}
      disabled={disabled}
      onClick={() => mutate({ staffIDs })}
    >
      Submit
    </Button>
  );
};

export default RightTableFooter;
