import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { constants, operations, Types } from "./duck";
import { FormattedMessage, useIntl } from "react-intl";
import { notification } from "antd";
import { useCountryList } from "hooks";

const GoogleAutocomplete: React.FC<Types.GoogleAutocompleteProps> = ({
  streetTypes,
  onChange,
  disabledProp,
}) => {
  const intl = useIntl();
  const [googleAddress, setGoogleAddress] = React.useState<any>("");
  const { countryList } = useCountryList();

  const handleSelectGoogleAddress = async (value: any | null) => {
    if (!value) {
      onChange(constants.INITIAL_VALUES);

      setGoogleAddress(value);
      return;
    }

    const addresses = await operations.getAddresses(
      value,
      countryList,
      streetTypes,
    );

    if (disabledProp && addresses.countryId) {
      const country = countryList.find((c) => c.id === addresses.countryId);

      if (country?.[disabledProp]) {
        notification.error({
          message: intl.formatMessage({
            id: "googleAutocomplete.disabledCountry",
          }),
        });

        return;
      }
    }

    onChange(addresses);

    setGoogleAddress(value);
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={constants.GOOGLE_API_KEY}
      selectProps={{
        value: googleAddress,
        isClearable: true,
        backspaceRemovesValue: true,
        onChange: handleSelectGoogleAddress,
        placeholder: <FormattedMessage id="googleAutocomplete.placeholder" />,
        noOptionsMessage: ({ inputValue }: any) => {
          if (!inputValue) {
            return <FormattedMessage id="googleAutocomplete.placeholder" />;
          }

          return "Not found";
        },
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: countryList.map((c) => c.iso_alpha_2),
        },
      }}
    />
  );
};

export default GoogleAutocomplete;
