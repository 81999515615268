import { useState, FC, ReactNode, useRef, useCallback } from "react";
import { Transfer, Table, Typography, ExtendedTag } from "components";
import { RightTableFooter } from "./components";
import _debounce from "lodash/debounce";
import difference from "lodash/difference";
import { getQueryKey } from "utils";
import { Staff, QueryKey } from "types";
import { Types, selectors } from "../../duck";
import { useMutation, useQuery } from "hooks";
import _uniqBy from "lodash/uniqBy";

interface StaffTransferProps {
  userID: number;
  visible: boolean;
  staffListLoading: boolean;
  dataSource: Types.TransformedStaff[];
  staffListQueryKey: QueryKey;
}

const filterOption = (
  text: string,
  { first_name, last_name, email }: Types.TransformedStaff,
) => {
  const searchString = `${first_name} ${last_name} ${email}`.toLowerCase();

  return searchString.toLowerCase().indexOf(text) > -1;
};

const listStyle = { flexBasis: "50%", height: 400, overflow: "auto" };

const StaffTransfer: FC<StaffTransferProps> = ({
  dataSource,
  staffListLoading,
  staffListQueryKey,
  userID,
  visible,
}) => {
  const touched = useRef<boolean>();
  const userStaffListQueryKey = getQueryKey("userStaffList", userID);
  const { data: userStaffList = [], isLoading: userStaffListLoading } =
    useQuery<Staff[], Types.TransformedStaff[]>({
      apiName: "users",
      enabled: visible,
      path: `/${userID}/staff`,
      queryKey: userStaffListQueryKey,
      select: (result) => selectors.changeStaffID(result),
    });
  const [searchValue, setSearchValue] = useState("");
  const {
    queryClient,
    mutate: search,
    isPending: searching,
  } = useMutation<Staff[], { value: string }>({
    method: "post",
    apiName: "staff",
    path: "/search",
    showMessage: false,
    onSuccess: (result) => {
      const joined = selectors.changeStaffID(result).concat(userStaffList);

      queryClient.setQueryData(staffListQueryKey, () => _uniqBy(joined, "id"));
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback<(arg: { value: string }) => void>(
    _debounce(search, 300),
    [search],
  );

  const targetKeys = userStaffList.map((item) => item.id);
  const preventEmpty = !targetKeys.length;

  return (
    <Transfer
      showSearch
      listStyle={listStyle}
      dataSource={dataSource}
      targetKeys={targetKeys}
      status={preventEmpty && !userStaffListLoading ? "error" : undefined}
      rowKey={(record) => record.id}
      titles={["All Staff", "Connected Staff"]}
      filterOption={filterOption}
      onSearch={(dir, value) => {
        if (dir === "left") {
          debouncedSearch({ value });
        } else {
          setSearchValue(value);
        }
      }}
      onChange={(newTargetKeys) => {
        touched.current = true;

        queryClient.setQueryData(userStaffListQueryKey, () =>
          dataSource.filter((item) => newTargetKeys.includes(item.id)),
        );
      }}
    >
      {({
        direction,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const tableProps: {
          dataSource: Types.TransformedStaff[];
          footer: () => ReactNode;
          loading?: boolean;
        } = {
          dataSource: [],
          footer: () => null,
        };

        if (direction === "left") {
          tableProps.loading = staffListLoading || searching;
          tableProps.dataSource = dataSource.map((item) => ({
            ...item,
            disabled: targetKeys.includes(item.id),
          }));
        } else {
          tableProps.loading = userStaffListLoading;
          tableProps.dataSource = dataSource.filter((item) => {
            if (searchValue) {
              return (
                targetKeys.includes(item.id) && filterOption(searchValue, item)
              );
            }

            return targetKeys.includes(item.id);
          });

          tableProps.footer = () => (
            <RightTableFooter
              disabled={preventEmpty || !touched.current}
              staffIDs={targetKeys}
              userID={userID}
              onUserStaffUpdated={(newStaff) => {
                queryClient.setQueryData<Types.TransformedStaff[]>(
                  staffListQueryKey,
                  () => {
                    const joined = dataSource.concat(
                      selectors.changeStaffID(newStaff),
                    );

                    return _uniqBy(joined, "id");
                  },
                );
              }}
            />
          );
        }

        return (
          <Table
            {...tableProps}
            showHeader={false}
            pagination={false}
            size="small"
            rowKey="id"
            rowSelection={{
              selectedRowKeys: listSelectedKeys,
              onSelect: (record, selected) => {
                return onItemSelect(record.id, selected);
              },
              getCheckboxProps: (item) => ({
                disabled: listDisabled || item.disabled,
              }),
              onSelectAll: (selected, selectedRows) => {
                const treeSelectedKeys = selectedRows
                  .filter((item) => !item.disabled)
                  .map(({ id }) => id);

                const diffKeys = selected
                  ? difference(treeSelectedKeys, listSelectedKeys)
                  : difference(listSelectedKeys, treeSelectedKeys);

                onItemSelectAll(diffKeys, selected);
              },
            }}
            columns={[
              {
                dataIndex: "id",
                render: (_, item) => (
                  <Typography.Paragraph
                    type={item.disabled ? "secondary" : undefined}
                  >
                    {item.first_name} {item.last_name} {item.email} <br />
                    <ExtendedTag backgroundColor={item.accountColor}>
                      {item.accountName}
                    </ExtendedTag>
                  </Typography.Paragraph>
                ),
              },
            ]}
            onRow={({ id, disabled }) => ({
              onClick: () => {
                if (disabled) {
                  return;
                }

                onItemSelect(id, !listSelectedKeys.includes(id));
              },
            })}
          />
        );
      }}
    </Transfer>
  );
};

export default StaffTransfer;
