import { FC, useState } from "react";
import { Form, FormItemProps } from "antd";
import PhoneInput from "react-phone-input-2";
import { FormattedMessage } from "react-intl";

const phoneProps = {
  inputStyle: { width: "100%" },
  enableSearch: true,
};

interface PhoneInputProps {
  value?: string | null;
  onChange?: (v: any) => void;
}

const CustomPhoneInput: FC<PhoneInputProps> = ({ value, onChange }) => {
  const [countryCode, setCountryCode] = useState<string>();

  const onInputChange = (
    newValue: string,
    countryData: { countryCode: string },
  ) => {
    if (onChange) {
      onChange(newValue);
    }

    setCountryCode(countryData.countryCode);
  };

  const country = !value ? { country: countryCode } : {};
  // const preferredCountries =
  //   language === "zh"
  //     ? ["cn", "tw", "sg", "us", "au"]
  //     : ["au", "us", "sg", "cn", "tw"];

  return (
    <PhoneInput
      {...country}
      {...phoneProps}
      value={value}
      onChange={onInputChange}
      enableSearch
      // preferredCountries={preferredCountries}
      // preserveOrder={["preferredCountries"]}
      masks={{ au: "(.) ....-...." }}
    />
  );
};

export default ({
  required,
  rules = [],
  label,
  ...props
}: FormItemProps & { label: string }) => {
  return (
    <Form.Item
      {...props}
      label={label && <FormattedMessage id={label} />}
      getValueProps={(value) => value && { value }}
      rules={[
        {
          required,
          message: "Can't be blank",
        },
        ...rules,
      ]}
    >
      <CustomPhoneInput />
    </Form.Item>
  );
};
