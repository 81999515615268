import { FC } from "react";
import {
  Form,
  InputSelect,
  AsyncSelect,
  InputText,
  Row,
  Col,
  SubmitButton,
  InputCheckbox,
  Tag,
} from "components";
import { CommonProps } from "../../types";
import { COMMON_COL_PROPS, ROW_GUTTER, COLORS } from "consts";
import { useSelector } from "hooks";
import { getQueryKey } from "utils";
import { AccountStatus } from "types";

const STATUS_COLOR: Record<number, string> = {
  1: COLORS.volcano[2],
  2: COLORS.blue[4],
  3: COLORS.green[5],
  4: COLORS.grey[4],
};

const Summary: FC<CommonProps> = ({ account, onFinish, updating }) => {
  const accountTypes = useSelector((state) => state.appState.accountTypes);

  return (
    <Form layout="vertical" initialValues={account} onFinish={onFinish}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="account_name" label="account.name" />

          <Row gutter={ROW_GUTTER}>
            <Col sm={12} md={12} xs={24}>
              <InputSelect
                required
                name="typeId"
                label="common.type"
                options={accountTypes}
                getOptionProps={(opt) => ({
                  children: opt.name,
                  value: opt.id,
                })}
              />
            </Col>
            <Col sm={12} md={12} xs={24}>
              <AsyncSelect<AccountStatus>
                required
                label="common.status"
                name="statusID"
                apiName="accounts"
                path="/statuses"
                queryKey={getQueryKey("accountStatuses")}
                getOptionProps={(opt) => ({
                  value: opt.id,
                  children: (
                    <Tag color={STATUS_COLOR[opt.id]}>{opt.status}</Tag>
                  ),
                })}
              />
            </Col>
          </Row>
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <InputText name="businessAddress" label="account.businessAddress" />
          <Row>
            <InputCheckbox
              name="disabledLive"
              label="account.disabledLive"
              labelPlacement="formItem"
            />
            <InputCheckbox
              name="disabledDemo"
              label="account.disabledDemo"
              labelPlacement="formItem"
            />
          </Row>
        </Col>
      </Row>
      <SubmitButton loading={updating} />
    </Form>
  );
};

export default Summary;
