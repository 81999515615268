import { useEffect } from "react";
import flatten from "flat";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, PreLoader } from "components";
import { authActions, configureAmplify } from "configs";
import { useDispatch, useSelector } from "hooks";
import Layout from "layout";
import messages from "translations";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const theme = {
  label_font_weight: 500,
};

configureAmplify();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  const { authenticating } = useSelector((state) => ({
    authenticating: state.auth.authenticating,
  }));

  useEffect(() => {
    dispatch(authActions.authenticateStart());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" messages={flatten(messages.en)}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            {authenticating ? <PreLoader /> : <Layout />}
          </QueryClientProvider>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
