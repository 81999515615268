import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Auth } from "utils";

export const loginStart = createAction(
  "loginStart",
  (payload: { email: string; password: string }) => ({
    payload,
  })
);

export const logOut = createAsyncThunk("SIGN_OUT", () => Auth.signOut());
