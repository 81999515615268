import { ReactNode } from "react";
import { ENDPOINTS } from "consts";
import { QueryKey } from "@tanstack/react-query";

export type { QueryKey };
export type { Params } from "react-router-dom";

export type { TableColumnType } from "antd";

export type ApiNames = keyof typeof ENDPOINTS;

export interface ApiArgs {
  apiName: ApiNames;
  path?: string;
  options?: {
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;
    body?: Record<string, any>;
  };
}

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
  response: Promise<Response>;
  cancel(abortMessage?: string): void;
}

interface ResponsePayload {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType>;
  text(): Promise<string>;
}

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
  body: ResponsePayload;
  statusCode: number;
  headers: Record<string, string>;
}

export interface RcFile extends File {
  uid: string;
}

export interface User {
  id: number;
  aws_cognito_id: string | null;
  confirmed: number;
  email: string | null;
  firstName: string | null;
  lastActiveStaffID: number | null;
  lastName: string | null;
}

export interface SelectOption {
  children?: ReactNode;
  value: any;
  removable?: boolean;
  disabled?: boolean;
}

export interface Billing {
  accountID: number;
  email: string;
  id: number;
  name: string;
  stripeCustomerID: string;
}

export interface Backoffice {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  aws_cognito_id: string;
}

export interface InitialData {
  backoffice: Backoffice;
  streetTypes: StreetType[];
  accountTypes: AccountType[];
}

export interface StaffPermission {
  account_admin: boolean;
  id: number;
  staff_admin: boolean;
  staff_view: boolean;
}

export interface Staff {
  id: number;
  aws_cognito_id: string;
  accountName: string;
  accountColor: string;
  accountStatus: string;
  email: string;
  file_by: string;
  account_id: number;
  lastUsedAccountId: number;
  status_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  tel_work: string;
  tel_home: string;
  tel_mobile: string;
  email_secondary: string;
}

export interface Country {
  id: number;
  label: string;
  full_name: string;
  iso_alpha_2: string;
  iso_alpha_3: string;
  un_code: string;
  deleted: boolean;
  priority: number;
  restrictedCountry: true;
  accountId: number;
  disabledLive: number;
  disabledDemo: number;
}

export interface AccountType {
  id: number;
  name: string;
}

export interface Account {
  id: number;
  disabled: boolean;
  file_by: string;
  status: string;
  color: string;
  billing?: Billing;
  optionalAccountID?: number;
  admin_user_id: number;
  account_slug: string;
  account_name: string;
  favicon: string;
  page_header_logo: string;
  product_name: string;
  website: string;
  desktop_app_name: string;
  mobile_app_name: string;
  desktop_windows_app_name: string;
  desktop_windows_app_link: string;
  desktop_mac_app_name: string;
  desktop_mac_app_link: string;
  ios_app_name: string;
  ios_app_link: string;
  android_app_name: string;
  android_app_link: string;
  web_app_name: string;
  streetAddress: StreetAddress;
  web_app_link: string;
  sender_email: string;
  customer_service_email: string;
  telephone: string;
  afsl: string;
  badge_color: string;
}

export interface StreetType {
  id: number;
  fileBy: string;
  textContentId: number;
}

export interface ExtendedDescriptionItem {
  label: string;
  translate?: boolean;
  value: React.ReactNode;
  copyable?: boolean;
}

export interface StreetAddress {
  id: number;
  streetTypeId: number;
  countryId: number;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface AccountStatus {
  id: number;
  status: string;
}

export enum ColumnFilterInputTypes {
  DATE_RANGE = "DATE_RANGE",
  INPUT = "INPUT",
}

export interface ViewHOCChildProps<TRecord> {
  record: TRecord;
  queryKey: QueryKey;
}

export interface QueryKeys {
  userList: "userList";
  userStaffList: "userStaffList";
  staffList: "staffList";
  staff: "staff";
  accountList: "accountList";
  accountStatuses: "accountStatuses";
  account: "account";
  countryList: "countryList";
  "street-address": "street-address";
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
