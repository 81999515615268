import { FC, useEffect, useState, useRef } from "react";
import { Table, Button, PlusOutlined, Tag } from "components";
import * as LC from "./components";
import { DashboardChildProps } from "hocs";
import { useQueryClient, useQuery } from "hooks";
import { getQueryKey } from "utils";
import { User, Staff } from "types";
import { Types, selectors } from "./duck";

const List: FC<DashboardChildProps> = ({ getTopics }) => {
  const queryClient = useQueryClient();
  const userListQueryKey = getQueryKey("userList");
  const originStaffList = useRef<Types.TransformedStaff[]>([]);
  const { data, isLoading } = useQuery<User[]>({
    apiName: "users",
    queryKey: userListQueryKey,
  });

  const [state, setState] = useState<{
    drawerOpen: boolean;
    expandedKey: number | null;
  }>({
    expandedKey: null,
    drawerOpen: false,
  });

  const staffListQueryKey = getQueryKey("staffList", state.expandedKey);
  const { data: staffList = [], isLoading: staffListLoading } = useQuery<
    Staff[],
    Types.TransformedStaff[]
  >({
    apiName: "staff",
    enabled: !!state.expandedKey,
    queryKey: staffListQueryKey,
    select: (queryData) => {
      const transformedList = selectors.changeStaffID(queryData);

      originStaffList.current = transformedList;

      return transformedList;
    },
  });

  useEffect(() => {
    getTopics({
      extra: (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              drawerOpen: true,
            }));
          }}
        />
      ),
    });
  }, [getTopics]);

  return (
    <>
      <LC.CreateNew
        drawerOpen={state.drawerOpen}
        userListQueryKey={userListQueryKey}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            drawerOpen: false,
          }));
        }}
      />
      <Table
        loading={isLoading}
        dataSource={data}
        expandable={{
          expandedRowKeys: state.expandedKey ? [state.expandedKey] : [],
          onExpand: (expanded, record) => {
            queryClient.setQueryData<Types.TransformedStaff[]>(
              staffListQueryKey,
              (oldData) => {
                if (state.expandedKey === record.id) {
                  return oldData;
                }

                return originStaffList.current;
              },
            );

            setState((prevState) => ({
              ...prevState,
              expandedKey: expanded ? record.id : null,
            }));
          },
          expandedRowRender: (record) => (
            <LC.StaffTransfer
              visible={state.expandedKey === record.id}
              userID={record.id}
              dataSource={staffList}
              staffListLoading={staffListLoading}
              staffListQueryKey={staffListQueryKey}
            />
          ),
        }}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend",
          },
          {
            title: "Cognito ID",
            dataIndex: "aws_cognito_id",
            sorter: (a, b) => a.id - b.id,
          },
          {
            title: "Confirmed",
            dataIndex: "confirmed",
            render: (_, record) => {
              const { text, color } = record.confirmed
                ? {
                    text: "yes",
                    color: "green",
                  }
                : {
                    text: "no",
                    color: "grey",
                  };

              return <Tag color={color}>{text}</Tag>;
            },
          },
          {
            title: "First Name",
            dataIndex: "firstName",
          },
          {
            title: "Last Name",
            dataIndex: "lastName",
          },
          {
            dataIndex: "email",
            title: "Email",
          },
        ]}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          pageSizeOptions: ["5", "10", "15"],
          defaultPageSize: 5,
          showSizeChanger: true,
        }}
      />
    </>
  );
};

export default List;
