import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Types } from "./duck";
import { actions as authActions } from "../auth";

const initialState: Types.State = {
  loading: false,
  streetTypes: [],
  accountTypes: [],
};

export const clients = createSlice({
  name: "appState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const matcher = isAnyOf(
      authActions.authenticateSuccess,
      authActions.loginSuccess,
    );

    builder.addMatcher(matcher, (state, { payload }) => {
      state.accountTypes = payload.accountTypes;
      state.streetTypes = payload.streetTypes;
    });
  },
});

export const { reducer, actions } = clients;
