import { FC } from "react";
import {
  Form,
  Row,
  Col,
  InputText,
  InputEmail,
  SubmitButton,
  ExtendedTag,
  AsyncSelect,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { CommonProps } from "../../types";
import { Account } from "types";
import { getQueryKey } from "utils";

const Summary: FC<CommonProps> = ({ staff, submitting, onFinish }) => {
  return (
    <Form layout="vertical" initialValues={staff} onFinish={onFinish}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <InputText required name="first_name" label="common.first_name" />
          <InputText required name="last_name" label="common.last_name" />

          <InputText name="middle_name" label="common.middle_name" />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <AsyncSelect<Account>
            required
            name="account_id"
            label="common.account"
            apiName="accounts"
            queryKey={getQueryKey("accountList")}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: (
                <ExtendedTag backgroundColor={opt.color}>
                  {opt.account_name}
                </ExtendedTag>
              ),
            })}
          />
          <InputEmail required />
        </Col>
      </Row>
      <SubmitButton loading={submitting} />
    </Form>
  );
};

export default Summary;
