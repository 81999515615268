import { FC } from "react";
import { Descriptions, DescriptionsProps, Empty, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { ExtendedDescriptionItem } from "types";

interface ExtendedDescriptionsProps
  extends Omit<DescriptionsProps, "title" | "items"> {
  title?: string;
  translateTitle?: boolean;
  items?: ExtendedDescriptionItem[];
}

const ExtendedDescriptions: FC<ExtendedDescriptionsProps> = ({
  items = [],
  size = "small",
  layout = "vertical",
  bordered = true,
  title,
  translateTitle = true,
  // loading = false,
  ...props
}) => {
  return (
    <Descriptions
      title={title && translateTitle ? <FormattedMessage id={title} /> : title}
      bordered={bordered}
      layout={layout}
      size={size}
      contentStyle={{ fontWeight: "bold" }}
      {...props}
    >
      {items.length ? (
        items.map(({ label, value, copyable, translate = true }, i) => (
          <Descriptions.Item
            // eslint-disable-next-line react/no-array-index-key
            key={label + i}
            label={translate ? <FormattedMessage id={label} /> : label}
          >
            <Typography.Text
              copyable={typeof copyable !== "undefined" ? copyable : !!value}
            >
              {value || "-"}
            </Typography.Text>
          </Descriptions.Item>
        ))
      ) : (
        <Descriptions.Item>
          <Empty />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default ExtendedDescriptions;
