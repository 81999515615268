import { Card } from 'components';

const DashboardPage = () => {

    return (
      <Card
        style={{ minHeight: 360 }}
        title='Dashboard'
      >
        <div style={{ paddingTop: 24 }}>
          PAGE COMPONENT GOES HERE
        </div>
      </Card>

    );
}

export default DashboardPage;
