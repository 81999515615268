import InputSelect from "../basic";
import { InputSelectProps } from "../types";
import { useQuery } from "hooks";
import { ApiNames, QueryKey } from "types";

interface AsyncSelectProps<TOption = any>
  extends Omit<InputSelectProps<TOption>, "options" | "loading"> {
  apiName: ApiNames;
  queryKey: QueryKey;
  path?: string;
}

const AsyncSelect = <TOption,>({
  apiName,
  path,
  queryKey,
  ...props
}: AsyncSelectProps<TOption>) => {
  const { data = [], isLoading } = useQuery<TOption[]>({
    queryKey,
    apiName,
    path,
  });

  return <InputSelect {...props} loading={isLoading} options={data} />;
};

export default AsyncSelect;
