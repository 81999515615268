import { useQuery } from "./tanstack";
import { Country } from "types";
import { getQueryKey } from "utils";
import { useSelector } from "./useReactRedux";

const useCountryList = () => {
  const backoffice = useSelector((state) => state.auth.backoffice);

  const queryKey = getQueryKey("countryList");

  const { data = [], isLoading } = useQuery<Country[]>({
    apiName: "system",
    path: "/countries",
    enabled: !!backoffice,
    queryKey,
  });

  return {
    queryKey,
    countryList: data,
    countryListLoading: isLoading,
  };
};

export default useCountryList;
