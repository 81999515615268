import { useState, useEffect } from "react";
import {
  Menu,
  DashboardOutlined,
  AccountBookOutlined,
  UserOutlined,
  TeamOutlined,
  Layout,
  NavLink,
} from "components";
import * as H from "hooks";
import { routes } from "configs";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  sider: {
    "& .ant-layout-sider-trigger": {
      position: "static",
      borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
    },
  },
  menu: {
    overflow: "auto",
    height: "100%",
  },
  itemGroup: {
    textAlign: "center",
  },
});

const MenuComponent = () => {
  const classes = useStyles();
  const [state, setState] = useState<{
    selectedKeys: string[];
    openKeys: string[];
    collapsed: boolean;
  }>({
    selectedKeys: ["dashboard"],
    openKeys: [],
    collapsed: !!localStorage.getItem("collapsedMenu"),
  });
  const { pathname } = H.useLocation();

  useEffect(() => {
    const openKeys = [pathname.slice(0, pathname.lastIndexOf("/"))];

    setState((prevState) => ({
      ...prevState,
      openKeys: state.collapsed ? prevState.openKeys : openKeys,
      selectedKeys: [pathname],
    }));
  }, [pathname, state.collapsed]);

  const onCollapse = (collapsed: boolean) => {
    if (collapsed) {
      localStorage.setItem("collapsedMenu", "true");
    } else {
      localStorage.removeItem("collapsedMenu");
    }
    setState((prevState) => ({ ...prevState, collapsed }));
  };
  const onMenuOpenChange = (openKeys: string[]) => {
    setState((prevState) => ({
      ...prevState,
      openKeys: [openKeys[openKeys.length - 1]],
    }));
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={state.collapsed}
      onCollapse={onCollapse}
      theme="light"
      className={classes.sider}
    >
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[routes.dashboard]}
        className={classes.menu}
        selectedKeys={state.selectedKeys}
        openKeys={state.openKeys}
        onOpenChange={onMenuOpenChange}
        items={[
          {
            key: routes.dashboard,
            title: "Dashboard",
            icon: <DashboardOutlined />,
            label: <NavLink to="/">Dashboard</NavLink>,
          },
          {
            key: routes.accounts.main,
            title: "Account",
            label: "Account",
            icon: <AccountBookOutlined />,
            children: [
              {
                key: routes.accounts.list(),
                title: "List",
                label: <NavLink to={routes.accounts.main}>List</NavLink>,
              },
            ],
          },
          {
            key: routes.staff.main,
            title: "Staff",
            label: "Staff",
            icon: <TeamOutlined />,
            children: [
              {
                key: routes.staff.list(),
                title: "List",
                label: <NavLink to={routes.staff.main}>List</NavLink>,
              },
            ],
          },
          {
            key: routes.users.main,
            title: "User",
            label: "User",
            icon: <UserOutlined />,
            children: [
              {
                key: routes.users.list(),
                title: "List",
                label: <NavLink to={routes.users.main}>List</NavLink>,
              },
            ],
          },
        ]}
      />
    </Layout.Sider>
  );
};

export default MenuComponent;
