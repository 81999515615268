const routes = {
  // accounts
  login: "/login",

  // account
  accounts: {
    main: "/accounts",
    list() {
      return `${this.main}/list`;
    },
    view(id?: number) {
      return `${this.main}/${id || ":id"}`;
    },
  },

  // dashboard
  dashboard: "/dashboard",

  users: {
    main: "/users",
    list() {
      return `${this.main}/list`;
    },
    create() {
      return `${this.main}/create`;
    },
    addStaff(id: number) {
      return `/${id}/staff`;
    },
  },

  // staff
  staff: {
    main: "/staff",
    list() {
      return `${this.main}/list`;
    },
    create() {
      return `${this.main}/create`;
    },
    view(id?: number | string) {
      return `${this.main}/${id || ":id"}`;
    },
  },
};

export default routes;
