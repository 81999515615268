import { pimwaLogo } from "assets";
import { NavLink } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { routes } from "configs";

const useStyles = createUseStyles({
  logo: {
    width: 200,
    display: "inherit",
    "@media (max-width: 768px)": {
      width: 150,
    },
    "@media (max-width: 500px)": {
      width: 125,
    },
  },
  link: {
    display: "inline-block",
  },
});

const Logo = () => {
  const classes = useStyles();

  return (
    <NavLink to={routes.login} className={classes.link}>
      <img alt="Page Header Logo" src={pimwaLogo} className={classes.logo} />
    </NavLink>
  );
};

export default Logo;
