import { ListenerEffectAPI, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "utils";
import { notification } from "antd";
import { authActions } from "../slices";
import { operations } from "./duck";

export default async (
  action: PayloadAction<{ email: string; password: string }>,
  listenerApi: ListenerEffectAPI<any, any>
) => {
  // Can cancel other running instances
  listenerApi.cancelActiveListeners();

  try {
    const { payload } = action as any;

    await Auth.signIn({
      username: payload.email.toLowerCase(),
      password: payload.password,
    });

    const { userId: cognitoID } = await Auth.getCurrentUser();

    const data = await operations.getInitialData(cognitoID);

    listenerApi.dispatch(authActions.loginSuccess(data));
  } catch (e: any) {
    listenerApi.dispatch(authActions.loginError());
    notification.error({
      message: e?.response?.data?.error || e.message,
    });
  }
};
