import { FC } from "react";
import {
  Form,
  Row,
  Col,
  InputEmail,
  PhoneInput,
  SubmitButton,
  InputText,
  InputSelect,
  CountriesSelect,
} from "components";
import { CommonProps } from "../../types";
import { useSelector } from "hooks";

const Contact: FC<CommonProps> = ({ staff, onFinish, submitting }) => {
  const { streetTypes } = useSelector((state) => ({
    streetTypes: state.appState.streetTypes,
  }));

  return (
    <Form layout="vertical" initialValues={staff} onFinish={onFinish}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col>
              <PhoneInput name="tel_home" label="phone.home" />
            </Col>
            <Col>
              <PhoneInput name="tel_work" label="phone.work" />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col>
              <PhoneInput name="tel_mobile" label="phone.mobile" />
            </Col>
            <Col>
              <InputEmail
                name="email_secondary"
                label="common.emailSecondary"
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <InputText name="addressSuburb" label="streetAddress.suburb" />
            </Col>
            <Col span={8}>
              <InputText name="addressState" label="streetAddress.state" />
            </Col>
            <Col span={8}>
              <InputText
                name="addressPostCode"
                label="streetAddress.postcode"
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <CountriesSelect
                name="addressCountryId"
                label="common.country"
                getOptionProps={(option: any) => ({
                  children: option.full_name,
                  value: option.id,
                })}
              />
            </Col>
            <Col span={8}>
              <InputSelect
                name="addressStreetTypeId"
                label="streetAddress.streetType"
                options={streetTypes}
                getOptionProps={(option) => ({
                  children: option.fileBy,
                  value: option.id,
                })}
              />
            </Col>
            <Col span={8}>
              <InputText
                name="addressStreetName"
                label="streetAddress.streetName"
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <InputText
                name="addressStreetNumber"
                label="streetAddress.streetNumber"
              />
            </Col>
            <Col span={8}>
              <InputText
                name="addressUnitNumber"
                label="streetAddress.unitNumber"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <SubmitButton loading={submitting} />
    </Form>
  );
};

export default Contact;
