import * as React from "react";
import InputText from "../input-text";
import { InputSelect, CountriesSelect } from "../input-select";
import GoogleAutocomplete from "../google-autocomplete";
import { Form, Row, Col, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { useSelector } from "hooks";

export interface StreetAddressProps {
  required?: boolean;
}

const TRIPLE_COL_PROPS = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 8,
};

const StreetAddress: React.FC<StreetAddressProps> = ({ required = true }) => {
  const { streetTypes } = useSelector((state) => ({
    streetTypes: state.appState.streetTypes,
  }));

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="streetAddress.title" />
      </Typography.Title>
      <Form.Item
        label={<FormattedMessage id="streetAddress.location" />}
        shouldUpdate
      >
        {({ setFieldsValue }) => (
          <GoogleAutocomplete
            disabledProp="disabledLive"
            streetTypes={streetTypes}
            onChange={(addresses) => {
              setFieldsValue(addresses);
            }}
          />
        )}
      </Form.Item>
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <InputText label="streetAddress.unitNumber" name="unitNumber" />
        </Col>
        <Col span={12}>
          <InputText label="streetAddress.streetNumber" name="streetNumber" />
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText
            label="streetAddress.streetName"
            name="streetName"
            required={required}
          />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <InputSelect
            required={required}
            label="streetAddress.streetType"
            name="streetTypeId"
            options={streetTypes}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.fileBy,
            })}
          />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.suburb" name="suburb" />
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.state" name="state" />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <InputText label="streetAddress.postcode" name="postcode" />
        </Col>
        <Col {...TRIPLE_COL_PROPS}>
          <CountriesSelect
            required={required}
            name="countryId"
            label="common.country"
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.full_name,
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export default StreetAddress;
