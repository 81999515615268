import { FC } from "react";
import { ExtendedDescriptions } from "components";
import { Billing } from "types";

interface BillingProps {
  record: Billing;
}

const BillingTab: FC<BillingProps> = ({ record }) => {
  return (
    <ExtendedDescriptions
      items={[
        {
          label: "Customer ID",
          translate: false,
          value: record.stripeCustomerID,
        },
        {
          label: "common.email",
          value: record.email,
        },
        {
          label: "common.name",
          value: record.name,
        },
      ]}
    />
  );
};

export default BillingTab;
