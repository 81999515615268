import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Types, asyncActions } from "./duck";
import { InitialData } from "types";
import { loginStart } from "./duck/actions";

const initialState: Types.State = {
  loading: false,
  loggingOut: false,
  authenticating: false,
  backoffice: null,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateStart: (state) => {
      state.authenticating = true;
    },
    authenticateError: (state) => {
      state.authenticating = false;
    },
    authenticateSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.authenticating = false;
      state.backoffice = payload.backoffice;
    },
    loginError: (state) => {
      state.loading = false;
    },
    loginSuccess: (state, { payload }: PayloadAction<InitialData>) => {
      state.loading = false;
      state.backoffice = payload.backoffice;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncActions.logOut.pending, (state) => {
      state.loggingOut = true;
    });

    builder.addCase(asyncActions.logOut.fulfilled, () => ({
      ...initialState,
      authenticating: false,
    }));

    builder.addCase(loginStart, (state) => {
      state.loading = true;
    });
  },
});

export const { reducer, actions } = auth;
