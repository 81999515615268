import { StreetAddressResult } from "./types";

export const GOOGLE_API_KEY = "AIzaSyDcl7niCiHaGb5HZz39XmljpTTpbdk9fkM";

export const INITIAL_VALUES: StreetAddressResult = {
  countryId: null,
  streetTypeId: null,
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  state: "",
  suburb: "",
  postcode: "",
};
