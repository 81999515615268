import * as React from "react";
import { Row, Form, Button } from "antd";

interface SubmitButtonProps {
  loading?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ loading }) => {
  return (
    <Row justify="center">
      <Form.Item shouldUpdate>
        {({ isFieldsTouched }) => (
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={!isFieldsTouched}
          >
            Submit
          </Button>
        )}
      </Form.Item>
    </Row>
  );
};

export default SubmitButton;
