import { useDispatch, useSelector } from "hooks";
import { authAsyncActions } from "configs";
import { createUseStyles } from "react-jss";
import { Row, Card, Form, InputText, InputEmail, Button } from "components";

const useStyles = createUseStyles({
  card: { maxWidth: 600 },
  form: { marginTop: 20 },
  row: {
    height: "100%",
  },
  wrapper: {
    minHeight: 360,
    backgroundColor: "#fff",
    padding: 24,
  },
});

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const onSubmit = async ({ email, password }: any) => {
    dispatch(
      authAsyncActions.loginStart({
        email,
        password,
      }),
    );
  };

  return (
    <Row justify="center" align="middle" className={classes.row}>
      <Card title="Login" className={classes.card}>
        <Form onFinish={onSubmit} layout="vertical" className={classes.form}>
          <InputEmail required />
          <InputText
            required
            type="Password"
            name="password"
            label="common.password"
          />
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form>
      </Card>
    </Row>
  );
};

export default Login;
