import { FC } from "react";
import { StreetAddress, Form, SubmitButton, Skeleton } from "components";
import { useChildAPI } from "hooks";
import { StreetAddress as StreetAddressType } from "types";

const AccountStreetAddress: FC<{ accountID: number }> = ({ accountID }) => {
  const { data, save, fetching, saving } = useChildAPI<StreetAddressType>({
    parentRoute: "accounts",
    childRoute: "street-address",
    parentID: accountID,
  });

  if (fetching) {
    return <Skeleton active loading />;
  }

  return (
    <Form
      initialValues={data || {}}
      layout="vertical"
      name="accountStreetAddress"
      onFinish={save}
    >
      <StreetAddress required />
      <SubmitButton loading={saving} />
    </Form>
  );
};

export default AccountStreetAddress;
