import { FC } from "react";
import {
  Form,
  Row,
  Col,
  InputEmail,
  PhoneInput,
  SubmitButton,
} from "components";
import { CommonProps } from "../../types";
import { COMMON_COL_PROPS, ROW_GUTTER } from "consts";

const Contact: FC<CommonProps> = ({ account, updating, onFinish }) => {
  return (
    <Form layout="vertical" initialValues={account} onFinish={onFinish}>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Row gutter={[12, 12]}>
            <Col>
              <InputEmail name="sender_email" label="Sender Email" />
            </Col>
            <Col>
              <InputEmail
                name="customer_service_email"
                label="Customer Service Email"
              />
            </Col>
            <Col>
              <PhoneInput name="telephone" label="Phone" />
            </Col>
          </Row>
        </Col>
      </Row>
      <SubmitButton loading={updating} />
    </Form>
  );
};

export default Contact;
