import { useState, FC, useEffect } from "react";
import { Tabs, Button, Tooltip, Icons } from "components";
import * as LC from "./components";
import { viewHOC, DashboardChildProps } from "hocs";
import { useMutation } from "hooks";
import { getQueryKey } from "utils";
import { Account, ViewHOCChildProps } from "types";

const View: FC<ViewHOCChildProps<Account> & DashboardChildProps> = ({
  record,
  getTopics,
  queryKey,
}) => {
  const [billingDrawerOoen, toggleBillingDrawer] = useState(false);
  const { billing } = record;

  useEffect(() => {
    if (!billing) {
      getTopics({
        extra: (
          <Tooltip title="Add billing">
            <Button
              icon={<Icons.DollarOutlined />}
              onClick={() => {
                toggleBillingDrawer(true);
              }}
            />
          </Tooltip>
        ),
      });
    } else {
      getTopics({ extra: null });
    }
  }, [billing, getTopics]);

  const { mutate: updateAccount, isPending: updating } = useMutation({
    apiName: "accounts",
    path: `/${record.id}`,
    method: "patch",
    invalidateQueries: [queryKey],
  });

  const COMMON_PROPS = {
    onFinish: updateAccount,
    updating,
    account: record,
  };
  return (
    <>
      <LC.AddBillingDrawer
        accountQueryKey={queryKey}
        accountID={record.id}
        open={billingDrawerOoen}
        onClose={() => {
          toggleBillingDrawer(false);
        }}
      />
      <Tabs
        defaultActiveKey="summary"
        items={[
          {
            key: "summary",
            label: "Summary",
            children: <LC.Summary {...COMMON_PROPS} />,
          },
          {
            key: "contact",
            label: "Contact",
            children: <LC.Contact {...COMMON_PROPS} />,
          },
          {
            key: "streetAddress",
            label: "Street Address",
            children: <LC.StreetAddress accountID={record.id} />,
          },
          ...(!billing
            ? []
            : [
                {
                  key: "billing",
                  label: "Billing",
                  children: <LC.Billing record={billing} />,
                },
              ]),
        ]}
      />
    </>
  );
};

export default viewHOC({
  Component: View,
  apiName: "accounts",
  pathFn: ({ params }) => `/${params.id}`,
  queryKeyFn: ({ params }) => getQueryKey("account", params.id),
});
