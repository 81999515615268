import { ReactNode, useRef, useState } from "react";
import { Button, DatePicker, Grid, Input, Table } from "antd";
import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { useIntl, useQueryParams } from "hooks";
import { Actions } from "./components";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Types } from "./duck";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import _ from "lodash";
import { ColumnFilterInputTypes, TableColumnType } from "types";

const useStyles = createUseStyles({
  rowMobile: {
    display: "block",
    marginBottom: 14,
    "&:not(:last-child)": {
      boxShadow: "0 5px 6px -2px #ddd",
    },
  },
  row: {
    backgroundColor: "#fff",
  },
  editableCell: {
    minWidth: 200,
  },
  cellClassName: {
    display: "flex",
    justifyContent: "space-between",
    "&:before": {
      content: "attr(data-title)",
      fontWeight: "bold",
    },
  },
});

function ExtendedTable<TRecord>({
  columns,
  dataSource = [],
  ...props
}: Types.ExtendedTableProps<TRecord>) {
  const { md } = Grid.useBreakpoint();
  const classes = useStyles();
  const intl = useIntl();
  const [queryParams, setParams] = useQueryParams();
  const [state] = useState<Types.State>({
    searchWords: [],
    rangeValues: null,
    searchedColumn: "",
  });

  const searchInput = useRef<{
    focus: () => void;
    select: () => void;
    blur: () => void;
  }>();

  const handleReset = ({
    clearFilters,
    confirm,
    dataIndex,
  }: {
    clearFilters?: () => void;
    confirm: FilterDropdownProps["confirm"];
    dataIndex: string;
  }) => {
    setParams(
      {
        ..._.omit(queryParams, [dataIndex]),
      },
      {
        replace: true,
      },
    );

    if (clearFilters) {
      clearFilters();
    }

    confirm({ closeDropdown: true });
  };

  const tableColumns: Types.ColumnResult<TRecord>[] = columns.map(
    ({
      onCell,
      translate = true,
      filterAsync,
      dataIndex,
      defaultFilteredValue,
      filterInputType,
      render,
      ...col
    }) => {
      const castedDataIndex = dataIndex as string;

      const title = translate
        ? intl.formatMessage({ id: col.title })
        : col.title;

      let defaultValue = queryParams[castedDataIndex] as any;

      if (defaultValue) {
        // we use qs.comma separator in setParams
        defaultValue = defaultValue.split(",");
      }

      if (
        defaultValue &&
        filterInputType === ColumnFilterInputTypes.DATE_RANGE
      ) {
        // it has to be a nested array
        // otherwise the first value will be displayed in onFilter
        defaultValue = [defaultValue.map((item: any) => dayjs(+item))];
      }

      const colProps: Types.ColumnResult<TRecord> = {
        ...col,
        defaultFilteredValue: defaultValue || defaultFilteredValue,
        title,
        dataIndex: castedDataIndex,
        onCell: (record, index) => {
          const cellProps = onCell?.(record, index);

          return {
            ...cellProps,
            "data-title": title,
            className: classNames(
              {
                [classes.cellClassName]: !md,
                [classes.editableCell]: col.editable,
              },
              cellProps?.className,
            ),
            // style: col.editable ? { minWidth: 200 } : { whiteSpace: "nowrap" },
          };
        },
        render: (value, record, index) => {
          if (render) {
            return render({
              value,
              record,
              index,
              highlighter:
                state.searchedColumn === dataIndex ? (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: "#ffc069",
                      padding: 0,
                    }}
                    searchWords={state.searchWords.map((el) => String(el))}
                    autoEscape
                    textToHighlight={(value || "").toString()}
                  />
                ) : (
                  value
                ),
            });
          }

          return value || "-";
        },
      };

      if (colProps.filters || !filterInputType) {
        return colProps;
      }

      let onFilter: TableColumnType<any>["onFilter"];
      let getFilterComponent: (
        props: Omit<FilterDropdownProps, "prefixCls" | "visible">,
      ) => ReactNode;

      switch (filterInputType) {
        case ColumnFilterInputTypes.DATE_RANGE:
          onFilter = (rangeValues, record) => {
            const [from, to] = rangeValues as any;

            return dayjs(record[castedDataIndex]).isBetween(from, to);
          };
          getFilterComponent = ({ setSelectedKeys, selectedKeys }) => {
            return (
              <div style={{ marginBottom: 5 }}>
                <DatePicker.RangePicker
                  showTime
                  value={(selectedKeys[0] as any) || null}
                  ref={(ref: any) => {
                    searchInput.current = ref;
                  }}
                  onChange={(dayjsValues) => {
                    if (dayjsValues) {
                      setSelectedKeys([dayjsValues as any]);
                    }
                  }}
                />
              </div>
            );
          };
          break;
        case ColumnFilterInputTypes.INPUT:
          onFilter = (value, record) => {
            return String(record[castedDataIndex])
              .toLowerCase()
              .includes(String(value).toLowerCase());
          };

          getFilterComponent = ({ setSelectedKeys, selectedKeys }) => {
            return (
              <Input
                ref={(ref: any) => {
                  searchInput.current = ref;
                }}
                value={selectedKeys[0]}
                onChange={(event) => {
                  setSelectedKeys([event.target.value]);
                }}
                style={{
                  width: 188,
                  marginBottom: 8,
                  display: "block",
                }}
              />
            );
          };
          break;
        default:
          break;
      }

      return {
        ...colProps,
        onFilter: filterAsync ? undefined : onFilter,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          close,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            {getFilterComponent({
              setSelectedKeys,
              selectedKeys,
              confirm,
              close,
            })}
            <Button
              type="primary"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
                marginRight: 8,
              }}
            >
              <FormattedMessage id="button.search" />
            </Button>
            <Button
              onClick={() => {
                handleReset({
                  clearFilters,
                  dataIndex: castedDataIndex,
                  confirm,
                });
              }}
              size="small"
              style={{ width: 90 }}
            >
              <FormattedMessage id="button.reset" />
            </Button>
          </div>
        ),
        filterIcon: (filtered) => {
          const style = filtered ? { color: "#1890ff" } : {};
          return <SearchOutlined {...style} />;
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.current?.focus();
            }, 100);
          }
        },
      };
    },
  );

  return (
    <Table
      {...props}
      dataSource={dataSource}
      onChange={(p, filters) => {
        const tmp = _.mapValues<any>(filters, (value: any) =>
          !Array.isArray(value?.[0])
            ? value
            : value[0].map((item: any) => item.valueOf()),
        );

        setParams(
          {
            ...queryParams,
            ...tmp,
          },
          {
            qsProps: {
              arrayFormat: "comma",
            },
          },
        );
      }}
      // eslint-disable-next-line
      // @ts-ignore
      columns={tableColumns}
      locale={{
        emptyText: <FormattedMessage id="messages.noData" />,
      }}
      onHeaderRow={() => {
        if (!md) {
          return {
            style: { display: "none" },
          };
        }

        return {};
      }}
      rowKey={(record) => {
        return record?.id || Math.random();
      }}
      rowClassName={classNames(classes.row, {
        [classes.rowMobile]: !md,
      })}
    />
  );
}

ExtendedTable.Actions = Actions;

export default ExtendedTable;
