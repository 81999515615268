import { SelectProps } from "antd";
import { IntlShape } from "react-intl";

export const getPlaceholder = (
  placeholder: SelectProps["placeholder"],
  intl: IntlShape,
) => {
  return placeholder && typeof placeholder === "string"
    ? intl.formatMessage({ id: placeholder, defaultMessage: "" })
    : placeholder;
};

export const filterOption = (
  input: string,
  option: { children: string } | undefined,
) => {
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
};
