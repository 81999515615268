import { Dashboard } from "../components";
import * as features from "features";
import { routes } from "configs";
import { dashboardHOC } from "hocs";

export const SECURED_ROUTES = [
  {
    path: routes.dashboard,
    Component: Dashboard,
  },
  {
    path: routes.accounts.main,
    Component: dashboardHOC({
      title: "Account List",
      Component: features.account.List,
    }),
  },
  {
    path: routes.accounts.view(),
    Component: dashboardHOC({
      title: "Account Details",
      Component: features.account.View,
    }),
  },
  {
    path: routes.staff.main,
    Component: dashboardHOC({
      title: "staff.list",
      Component: features.staff.List,
    }),
  },
  {
    path: routes.staff.view(),
    Component: dashboardHOC({
      title: "staff.details",
      Component: features.staff.View,
    }),
  },
  {
    path: routes.users.main,
    Component: dashboardHOC({
      title: "user.list",
      Component: features.user.List,
    }),
  },
];
