import { ReactNode } from "react";
import { Empty, Skeleton as AntSkeleton } from "components";
import { useParams, useQueryParams, useQuery } from "hooks";
import { ApiNames, Params, QueryKey } from "types";

const viewHOC =
  <TRecord = any,>({
    Component,
    pathFn,
    queryKeyFn,
    apiName,
    skeleton = <AntSkeleton active loading />,
  }: {
    Component: any;
    queryKeyFn: (args: {
      params: Params<"id">;
      queryParams: Record<string, string>;
    }) => QueryKey;
    apiName: ApiNames;
    pathFn?: (args: {
      params: Params<"id">;
      queryParams: Record<string, string>;
    }) => string;
    skeleton?: ReactNode;
  }) =>
  (props: any) => {
    const params = useParams<"id">();
    const [queryParams] = useQueryParams();
    const queryKey = queryKeyFn({ params, queryParams });
    const { data, isLoading } = useQuery<TRecord>({
      apiName,
      queryKey,
      path: pathFn?.({ params, queryParams }),
      options: {
        queryParams,
      },
    });

    if (isLoading) {
      return skeleton as any;
    }

    if (!data && !isLoading) {
      return <Empty />;
    }

    return <Component {...props} record={data} queryKey={queryKey} />;
  };

export default viewHOC;
