import { useCallback, useState, ReactNode, FC } from "react";
import { Divider, Card, Typography } from "antd";

import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";

interface Topics {
  title?: string;
  subTitle?: string;
  extra?: any;
}

export interface DashboardChildProps {
  getTopics(args: Topics): void;
  getDrawerProps?: (args: { layout: ReactNode }) => void;
}

interface DashboardHOC<P> {
  Component: FC<P>;
  title: string;
  subTitle?: string;
}

const useStyles = createUseStyles({
  header: {
    borderBottom: "1px solid rgb(235, 237, 240)",
    marginBottom: 16,
  },
  card: { "& .ant-card-body": {} },
});

export const dashboardHOC =
  <P extends DashboardChildProps>({
    Component,
    title,
    subTitle,
  }: DashboardHOC<P>) =>
  (props: any) => {
    const classes = useStyles();
    const [state, setState] = useState<Topics & { drawerOpen: boolean }>({
      drawerOpen: false,
      title,
      subTitle,
      extra: [],
    });

    const getTopics = useCallback((newValues: Topics) => {
      setState((prevState) => ({
        ...prevState,
        ...newValues,
      }));
    }, []);

    return (
      <Card
        className={classes.card}
        title={<FormattedMessage id={state.title} />}
        extra={state.extra}
      >
        {subTitle && (
          <>
            <Typography.Title level={5}>{subTitle}</Typography.Title>
            <Divider />
          </>
        )}
        <Component {...props} getTopics={getTopics} />
      </Card>
    );
  };
