import { FC } from "react";
import {
  Drawer,
  Button,
  Form,
  InputText,
  InputEmail,
  CountriesSelect,
} from "components";
import { useMutation } from "hooks";
import { Account, QueryKey } from "types";

interface AddBillingDrawerProps {
  accountID: number;
  open: boolean;
  onClose: () => void;
  accountQueryKey: QueryKey;
}

interface AddBillingVars {
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

const AddBillingDrawer: FC<AddBillingDrawerProps> = ({
  open,
  onClose,
  accountQueryKey,
  accountID,
}) => {
  const [form] = Form.useForm();
  const { mutate, isPending } = useMutation<Account, AddBillingVars>({
    apiName: "accounts",
    path: `/${accountID}/billing`,
    method: "post",
    invalidateQueries: [accountQueryKey],
    onSuccess: () => onClose(),
  });

  return (
    <Drawer
      title="Provide billing details"
      open={open}
      onClose={onClose}
      destroyOnClose
      footer={
        <Button
          onClick={() => form.submit()}
          loading={isPending}
          type="primary"
        >
          Submit
        </Button>
      }
    >
      <Form<AddBillingVars>
        layout="vertical"
        form={form}
        preserve={false}
        onFinish={mutate}
      >
        <InputText required name="name" label="common.name" />
        <InputEmail required />
        <InputText
          required
          placeholder="City, district, suburb, town, or village."
          name="line1"
          label="common.addressLine1"
        />
        <InputText
          name="line2"
          placeholder="Address line 2 (e.g., apartment, suite, unit, or building)."
          label="common.addressLine2"
        />
        <CountriesSelect
          required
          name="country"
          placeholder="Two-letter country code (ISO 3166-1 alpha-2)"
          getOptionProps={(opt) => ({
            value: opt.iso_alpha_2,
            children: opt.full_name,
          })}
        />
        <InputText
          required
          name="city"
          placeholder="City, district, suburb, town, or village."
          label="common.city"
        />
        <InputText
          required
          name="state"
          placeholder="State, county, province, or region."
          label="streetAddress.state"
        />
        <InputText
          required
          placeholder="ZIP or postal code."
          name="postal_code"
          label="streetAddress.postcode"
        />
      </Form>
    </Drawer>
  );
};

export default AddBillingDrawer;
