import * as React from "react";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({ error: { color: "red" } });

interface ClipboardProps {
  children: string;
}

const Clipboard: React.FC<ClipboardProps> = ({ children }) => {
  const classes = useStyles();

  const [copied, setCopied] = React.useState(false);
  const [copyError, setCopyError] = React.useState(false);

  const onClick = () => {
    const selection = window.getSelection();
    const div = document.createElement("div");

    div.textContent = children;
    div.style.cssText = `
      position: absolute;
      opacity: 0;
      left: -9999px;
    `;

    document.body.append(div);

    if (!selection) {
      return;
    }

    try {
      const range = document.createRange();

      range.selectNodeContents(div);
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand("copy");
      selection.removeAllRanges();

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 100);

      if (copyError) {
        setCopyError(false);
      }
    } catch (e) {
      setCopyError(true);
    }

    document.body.removeChild(div);
  };

  return (
    <>
      {children}
      &nbsp;
      {copyError && <span className={classes.error}>Failed to copy</span>}
      &nbsp;
      <Button
        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
        onClick={onClick}
      />
    </>
  );
};

export default Clipboard;
