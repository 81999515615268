import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { Auth } from "utils";
import { authActions } from "../slices";
import { operations } from "./duck";

// Create the middleware instance and methods
export default async (listenerApi: ListenerEffectAPI<any, any>) => {
  try {
    const { userSub } = await Auth.fetchAuthSession();

    if (!userSub) {
      listenerApi.dispatch(authActions.authenticateError());
      return;
    }

    const data = await operations.getInitialData(userSub);

    listenerApi.dispatch(authActions.authenticateSuccess(data));
  } catch (e: any) {
    listenerApi.dispatch(authActions.authenticateError());
  }
};
