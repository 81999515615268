import { FC } from "react";
import {
  InputEmail,
  AsyncSelect,
  InputText,
  InputPassword,
  ExtendedTag,
  Form,
  Button,
  FormattedMessage,
  Drawer,
} from "components";
import { getQueryKey } from "utils";
import { Account, User, QueryKey } from "types";
import { useMutation } from "hooks";

interface FormValues {
  fistName: string;
  lastName: string;
  email: string;
  accountID: number;
}

interface CreateNewProps {
  drawerOpen: boolean;
  onClose: () => void;
  userListQueryKey: QueryKey;
}

const CreateNew: FC<CreateNewProps> = ({
  drawerOpen,
  onClose,
  userListQueryKey,
}) => {
  const [form] = Form.useForm();
  const { mutate, isPending } = useMutation<User, FormValues>({
    method: "post",
    apiName: "users",
    invalidateQueries: [userListQueryKey],
    onSuccess: () => onClose(),
  });

  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      destroyOnClose
      extra={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          <FormattedMessage id="button.submit" />
        </Button>
      }
    >
      <Form<FormValues>
        form={form}
        preserve={false}
        layout="vertical"
        onFinish={(values) => mutate(values)}
      >
        <AsyncSelect<Account>
          required
          name="account_id"
          label="common.account"
          apiName="accounts"
          queryKey={getQueryKey("accountList")}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: (
              <ExtendedTag backgroundColor={opt.color}>
                {opt.account_name}
              </ExtendedTag>
            ),
          })}
        />
        <InputText required name="firstName" label="common.first_name" />
        <InputText required name="lastName" label="common.last_name" />
        <InputEmail required name="email" label="common.email" />
        <InputPassword required name="password" label="common.password" />
      </Form>
    </Drawer>
  );
};

export default CreateNew;
