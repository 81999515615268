export const COMMON_COL_PROPS = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
